import React from 'react'
import Seo from '../components/seo/seo'
import { useTranslation } from 'react-i18next'
import Layout from '../layout/layout'
import SvgHeraLaundry from '../illustrations/home/heraLaundry'

const ContactPage = ({ data }) => {
  const { t } = useTranslation()

  return(
  <Layout>
    <Seo
      title={t('contact.seoTitle')}
      description={t('contact.seoDescription')}
      //seoImage={data.backgroundImage.childImageSharp.resize.src}
    />
    <div className="section is-medium is-light is-relative">
    <svg width="100vw" height="140vh" viewBox="0 0 100vw 140vh" style={{position: "absolute", display: 'inline-block', top: "0", left: 0, fillRule: "evenodd", clipRule: "evenodd", strokeLinecap:"round", strokeLinejoin:"round"}} xmlns="http://www.w3.org/2000/svg" space="preserve" >
      <defs>
      <linearGradient y1="364.683" id="LinearGradient" x1="-2.95224" y2="364.683" x2="2005.71" gradientUnits="userSpaceOnUse" gradientTransform="matrix(1 0 0 1 0 0)">
      <stop stop-color="#7dd1a7" offset="0"/>
      <stop stop-color="#71bdf4" offset="1"/>
      </linearGradient>
      </defs>
      <g id="Livello 1" layerName="Livello 1">
      <path d="M-1.43513+0.575764L2002.67+0.575764L2005.71+487.57C2005.71+487.57+1828.59+365.063+1448.93+425.368C1069.27+485.673+843.598+732.205+487.076+728.791C122.694+725.302-2.95224+554.323-2.95224+554.323L-1.43513+0.575764Z" opacity="1" fill="url(#LinearGradient)"/>
      </g>
    </svg>
      {/* <div
        style={{
          position: 'absolute',
          zIndex: 1,
          overflow: 'hidden',
          bottom: 0,
          top: 0,
          height: '100%',
          width: '100%',
          backgroundSize: 'cover',
        }}
      >
        <ContactUsSvg />
      </div> */}
      <div className="container" style={{ position: 'relative', zIndex: 5 }}>
        <div className="columns">
          <div className="column is-1"></div>
          <div className="column is-5">
            <div className="content is-large">
              <h1 className="title is-white is-1 is-bottom-bordered-white">{<>{t('contact.pageTitle')}</>}</h1>
            
              <p className="title is-4">{t('contact.pageSubtitle')}</p>
              <SvgHeraLaundry />
            </div>
          </div>
          <div className="column is-1"></div>
          <div
            className="column is-4 is-soft-gradient-top"
            style={{ padding: '50px' }}
          >
            {/* Contact form */}

            <form
              name="hera-contact"
              method="POST"
              netlify-honeypot="bot-field"
              data-netlify="true"
              action={`/grazie`}
            >
              <input type="hidden" name="form-name" value="hera-contact" />
              <input type="hidden" name="bot-field" />
              <div className="field">
                <label className="label">
                  {t('contact.formFullName')}
                </label>
                <input className="input" type="text" name="fullname"></input>
              </div>
              <div className="field">
                <label className="label">
                  {t('contact.formCompanyName')}
                </label>
                <input className="input" type="text" name="companyname"></input>
              </div>
              <div className="field">
                <label className="label">{<>{t('contact.formEmail')}</>}</label>
                <input className="input" type="email" name="email"></input>
              </div>
              <div className="field">
                <label className="label">
                  {t('contact.formSubject')}
                </label>
                <input className="input" type="text" name="subject"></input>
              </div>
              <div className="field">
                <label className="label">
                  {t('contact.formMessage')}
                </label>
                <div className="control">
                  <textarea className="textarea" name="message"></textarea>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label className="checkbox">
                    <input type="checkbox" name="privacy" />{' '}
                    {t('contact.formPrivacy')}
                  </label>
                </div>
              </div>
              <button
                type="submit"
                className="button is-dark is-medium is-fullwidth"
              >
                {t('contact.formSend')}
              </button>
            </form>

            {/* End of contact form */}
          </div>
        </div>
      </div>
    </div>

  </Layout>
)
}

export default ContactPage

// export const contactQuery = graphql`
//   query {
//     backgroundImage: file(relativePath: { eq: "heros/contact/ContactUs.png" }) {
//       childImageSharp {
//         fluid(maxWidth: 4000) {
//           ...GatsbyImageSharpFluid
//         }
//         resize(width: 900, quality: 90) {
//           src
//         }
//       }
//     }
//   }
// `
